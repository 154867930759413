import { MetaFunction, json } from '@remix-run/cloudflare';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react';
import { FirebaseProvider } from './components/FirebaseContext';
import styles from './styles/app.css';
import { withSentry } from '@sentry/remix';

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'Creator Camp: TEXTGEN',
  viewport: 'width=device-width,initial-scale=1',
});

export function links() {
  return [{ rel: 'stylesheet', href: styles }];
}

export function ErrorBoundary() {
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div>Oh no. There was an error. We're working on it</div>
        <div>
          You can try refreshing the page to see if that fixes everything.
        </div>
        <Scripts />
      </body>
    </html>
  );
}

export async function loader() {
  return json<{ CLIENT_ENV: { USE_LOCAL_AUTH: boolean } }>({
    CLIENT_ENV: {
      USE_LOCAL_AUTH: (globalThis as any).USE_LOCAL_AUTH === 'true',
    },
  });
}

function App() {
  const data = useLoaderData<typeof loader>();
  const isProd = process.env.NODE_ENV === 'production';

  return (
    <FirebaseProvider>
      <html lang="en">
        <head>
          <Meta />
          <Links />
        </head>
        <body className="bg-cc-300 text-white">
          <Outlet />
          {!isProd && (
            <script
              dangerouslySetInnerHTML={{
                __html: `window.DEV_ENV = ${JSON.stringify(data.CLIENT_ENV)}`,
              }}
            />
          )}
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </body>
      </html>
    </FirebaseProvider>
  );
}

export default withSentry(App, {
  wrapWithErrorBoundary: false,
  errorBoundaryOptions: {
    fallback: (
      <p>There was an error. Refresh the page to see if it fixes things.</p>
    ),
  },
});
